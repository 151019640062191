<template>
    <div>
        <div class="w-full bg-white" id="header">
            <div class="flex w-full justify-between shadow header-title">
                <h1 class="text-base xl:text-2xl lg:text-2xl py-3 px-3 text-white">NusaBali ePaper</h1>
                <div class="" :class="{ 'pt-3': !isSubscribed, 'xl:pt-4': !isSubscribed, 'lg:pt-4': !isSubscribed, }">
                    <router-link v-if="! isSubscribed" to="/langganan" class="px-4 py-1 xl:py-2 lg:py-2 mr-2 my-2 no-underline rounded border border-blue-dark text-base text-white hover:text-blue-dark bg-blue-dark hover:bg-white">Berlangganan</router-link>
                    <v-popover
                      offset="16" v-if="isSubscribed"
                      class="inline"
                    >
                        <button class="tooltip-target b3 px-1 py-1 xl:px-4 lg:px-4 xl:py-2 lg:py-2 mr-2 my-2 text-base text-white hover:text-blue-dark cursor-pointer"><span class="icon-info-circled-alt"></span></button>

                        <template slot="popover">
                            <router-link to="/pembelian" class="underline cursor-pointer text-white">Berlangganan hingga: {{subscribedUntil}}</router-link>
                        </template>
                    </v-popover>
                    <a v-on:click="doLogout()" v-if="isLoggedIn" class="px-1 py-1 xl:px-4 lg:px-4 xl:py-2 lg:py-2 mr-2 my-2 underline text-base text-white hover:text-blue-dark cursor-pointer">
                        <span class="icon-logout xl:hidden lg:hidden"></span><span class="hidden xl:inline lg:inline">Logout</span></a>
                </div>
            </div>

            <div class="controls w-full py-1 xl:py-3 lg:py-3 shadow text-center flex justify-between bg-grey-lighter">
                <div class="px-1 py-1 lg:px-4 xl:px-4 self-center">
                    <button v-on:click="prev()" :disabled="!isEnabledPrev" class="px-2 py-2 hover:bg-blue-dark hover:text-white rounded bg-white border border-blue-dark text-blue-dark" :class="{ 'disabled': !isEnabledPrev}"><span class="icon-left"></span> <span class="hidden lg:inline xl:inline">Sebelumnya</span></button>
                </div>
                <div class="px-1 py-1 lg:px-4 xl:px-4 flex justify-between">
                    <div class="w-1/2" v-tooltip="{
                        content: 'Pilih Edisi',
                        placement: 'top'
                    }">
                        <datepicker @selected="changeEditionDate($event)" :value="currentDate" class="w-full px-3 py-2 rounded bg-white border border-blue-dark" :input-class="'w-full'"></datepicker>
                    </div>
                    <select v-model="currentPageId" @change="choosePage($event)" class="px-3 py-2 rounded bg-white border border-blue-dark" v-tooltip="{
                        content: 'Pilih Halaman',
                        placement: 'top'
                    }">
                        <option value="">-- Halaman --</option>
                        <option v-for="page in pages" v-bind:value="page.epaper_page_id" :key="page.page_number">{{page.page_number}}</option>
                    </select>
                </div>
                <div class="px-1 py-1 lg:px-4 xl:px-4 self-center">
                    <button v-on:click="next()" :disabled="!isEnabledNext" class="px-2 py-2 hover:bg-blue-dark hover:text-white rounded bg-white border border-blue-dark text-blue-dark" :class="{ 'disabled': !isEnabledNext}"><span class="hidden lg:inline xl:inline">Selanjutnya</span> <span class="icon-right"></span></button>
                </div>
            </div>
        </div>
        <div class="w-full bg-grey-darkest" id="reader">
            <div class="w-full mx-auto">
                <div v-if="! emptyEdition">
                    <transition>
                        <img class="main-page w-full" v-show="isLoad" :src="currentImage" @load="loaded($event)">
                    </transition>
                </div>
                <div v-if="emptyEdition" class="py-64">
                    <h4 class="text-center text-white">Maaf, edisi tidak dapat dimuat</h4>
                </div>
                <div v-show="!isLoad" class="h-full"><spinner /></div>
            </div>
            <footer class="w-full h-12 bg-blue-darkest text-white flex justify-between" style="margin-top: -4px">
                <div class="p-4 text-xs xl:text-base lg:text-base">&copy; 2019 NusaBali.com</div>
                <div class="p-4">
                    <ul class="no-underline">
                        <li class="list-none inline-block mr-2"><a target="_blank" href="https://www.facebook.com/NusaBalicom/" class="text-white hover:text-grey"><span class="icon-fb"></span></a></li>
                        <li class="list-none inline-block mr-2"><a target="_blank" href="https://twitter.com/nusabalicom" class="text-white hover:text-grey"><span class="icon-tw"></span></a></li>
                        <li class="list-none inline-block mr-2"><a target="_blank" href="https://www.instagram.com/nusabali_com/" class="text-white hover:text-grey"><span class="icon-ig"></span></a></li>
                        <li class="list-none inline-block mr-2"><a target="_blank" href="https://www.youtube.com/channel/UC-rhSRguZa-2ceDFaIp59kw" class="text-white hover:text-grey"><span class="icon-yt"></span></a></li>
                    </ul>
                </div>
            </footer>
        </div>
        <div v-if="isPopUpDisplayed" class="">
            <div class="modal-mask fixed pin-t w-full h-full">
                <div class="modal-window w-full pt-8 xl:w-1/2 lg:w-1/2 mx-auto">
                    <div class="modal-control flex w-5/6 justify-end ml-12 lg:ml-8 xl:ml-8">
                        <span v-on:click="closeLanggananPopUP()" class="icon-cancel cursor-pointer text-white text-2xl"></span>
                    </div>
                    <langganan-pop-up />
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    #header {
        background-color: rgba(255,255,255,.8);
    }
    #controls {
        background-color: rgba(255,255,255,.3);
    }
    .modal-mask {
        z-index: 9998;
        background-color: rgba(0,0,0,.5);
        display: table;
        transition: opacity .3s ease;
    }
    .main-page {
        transition: all .3s ease;
    }
    .disabled {
        border-color: #2e2e2e;
        background-color: #eee;
        color: #2e2e2e;
    }
    .disabled:hover {
        border-color: #2e2e2e;
        background-color: #eee;
        color: #2e2e2e;
    }
</style>

<script>
    import LanggananPopUp from './LanggananPopUp'
    import Spinner from './Spinner'
    import Datepicker from 'vuejs-datepicker'
    import {
        getFbSdk,
        fbLogin,
        fbLogout,
        getFbLoginStatus
    } from '../../node_modules/vue-facebook-login-component/src/modules/helpers.js'

    export default {
        components: {
            'langganan-pop-up': LanggananPopUp,
            'spinner': Spinner,
            Datepicker
        },
        data() {
            return {
                edition: {},
                pages: [],
                currentEditionObj: {},
                currentPageId: '',
                currentPageObj: {},
                currentPageNumber: '-- Halaman --',
                currentImage: '',
                currentImage64: '',
                isEnabledPrev: false,
                isEnabledNext: false,
                prevPageObj: {},
                nextPageObj: {},
                isSubscribed: false,
                isPopUpDisplayed: false,
                baseAPIUrl: process.env.VUE_APP_API_BASE_URL,
                sessionHeaderName: process.env.VUE_APP_SESSION_HEADER_NAME,
                params: {},
                isLoad: false,
                isLoggedIn: false,
                currentDate: new Date(),
                emptyEdition: false,
                subscribedUntil: ''
            }
        },
        localStorage: {
            nbe: {
                type: String,
                default: ''
            }
        },
        methods: {
            loadImg() {
                this.isLoad = false
            },
            loaded() {
                this.isLoad = true
            },
            checkSession: function(nbe) {
                if (this.$storage.get('nbe') !== nbe) {
                    this.$storage.remove('nbe')
                    this.$router.push('/login')
                }
            },
            changeEditionDate: function(event) {

                let currEventDate = event.getDate()
                let currEventMonth = event.getMonth() + 1 //Months are zero based
                let currEventYear = event.getFullYear()
                let currEventStr = currEventYear + '-' + currEventMonth + '-' + currEventDate

                let currDate = this.currentDate.getDate()
                let currMonth = this.currentDate.getMonth() + 1 //Months are zero based
                let currYear = this.currentDate.getFullYear()
                let currDateStr = currYear + '-' + currMonth + '-' + currDate

                this.$gtm.trackEvent({
                    category: 'Epaper',
                    action: 'Change Edition',
                    label: 'Change to Edition ' + currDateStr,
                    value: 0,
                    noninteraction: false // Optional
                })

                if (currDateStr != currEventStr) {
                    this.currentDate = event
                    this.changeEdition()
                }
            },
            choosePage: function(event) {
                if (event.target.value) {
                    if (event.target.value !== this.currentPageNumber) {
                        this.changePage(event.target.value)
                    }
                }
            },
            changePage: async function(cpid) {
                this.loadImg()
                this.currentPageId = parseInt(cpid)
                if (this.currentPageId !== '-- Halaman --') {
                    // change to the first page of selected edition
                    let that = this
                    let currPg = this.currentEditionObj.epaperpages.filter(function(elem) {
                        if(elem.epaper_page_id == that.currentPageId) return elem;
                    });

                    let nxtPg = this.currentEditionObj.epaperpages.filter(function(elem) {
                        if(elem.epaper_page_id > that.currentPageId) return elem;
                    });

                    let prvPg = this.currentEditionObj.epaperpages.filter(function(elem) {
                        if(elem.epaper_page_id < that.currentPageId) return elem;
                    });

                    if (currPg.length > 0) {
                        this.currentPage = currPg[0]
                        this.currentPageNumber = this.currentPage.page_number
                        this.currentImage64 = await this.getImgBase64(this.baseAPIUrl + 'users/editions/' + this.edition.epaper_id + '/pages/' + this.currentPageId)
                        if (this.currentImage64) {
                            this.currentImage = 'data:image/png;base64, ' + this.currentImage64
                            // set prev and next
                            if (nxtPg.length > 0) {
                                this.nextPageObj = nxtPg[0]
                                this.isEnabledNext = true
                            } else {
                                this.isEnabledNext = false
                            }

                            if (prvPg.length > 0) {
                                this.prevPageObj = prvPg[0]
                                this.isEnabledPrev = true
                            } else {
                                this.isEnabledPrev = false
                            }
                        }
                        this.isLoad = true
                    }
                }
            },
            displayLanggananPopUP: function() {
                if (! this.isPopUpDisplayed) {
                    this.isPopUpDisplayed = true
                }
            },
            closeLanggananPopUP: function() {
                if (this.isPopUpDisplayed) {
                    this.isPopUpDisplayed = false
                }
            },
            getImgBase64: function(url) {
                return this.axios.get(url + '?ts=' + new Date().getTime(), {
                        responseType: 'arraybuffer',
                        headers: {
                            'X-NBE': this.$storage.get('nbe')
                        }
                    })
                    .then(response => new Buffer(response.data, 'binary').toString('base64'))
                    .catch(error => {
                        if (error.response.status === 402) {
                            this.displayLanggananPopUP()
                        }
                        return ''
                    })
            },
            prev: function() {
                this.changePage(this.prevPageObj.epaper_page_id)
            },
            next: function() {
                this.changePage(this.nextPageObj.epaper_page_id)
            },
            async doFBlogout() {
                const logout = fbLogout()
                const response = await this.doAsync(logout)
                this.connected = false
                this.$emit('logout', response)
                return logout
            },
            doLogout: function() {
                this.axios.post(this.baseAPIUrl + 'logout', {}, {
                    headers: {
                        'X-NBE': this.$storage.get('nbe')
                    }
                }).then(() => {
                    if (this.$storage.get('source') === 'facebook') {
                        // logout facebook
                        this.doFBlogout()
                    }
                }).finally(() => {
                    // clear localstorage
                    this.$storage.clear(true)
                    this.isLoggedIn = false
                })
            },
            changeEdition: function() {
                this.loadImg()
                this.pages = []
                this.edition = {}
                let currDate = this.currentDate.getDate()
                let currMonth = this.currentDate.getMonth() + 1 //Months are zero based
                let currYear = this.currentDate.getFullYear()
                let currDateStr = currYear + '-' + currMonth + '-' + currDate

                this.axios.get(this.baseAPIUrl + 'users/editions/' + currDateStr + '/?ts=' + new Date().getTime(), {
                    headers: {
                        'X-NBE': this.$storage.get('nbe')
                    }
                })
                .then(async (response) => {
                    if (response.headers[this.sessionHeaderName]) {
                        this.checkSession(response.headers[this.sessionHeaderName])
                    }

                    this.edition = response.data.data
                    this.currentEditionObj = this.edition
                    this.pages = this.edition.epaperpages

                    await this.changePage(this.edition.epaperpages[0].epaper_page_id)
                    this.emptyEdition = false
                    this.currentPageNumber = this.edition.epaperpages[0].page_number
                    this.isSubscribed = response.data.subscribed_until ? true : false
                    this.subscribedUntil = response.data.subscribed_until
                })
                .catch((error) => {
                    this.emptyEdition = true
                    this.currentPageNumber = '-- Halaman --'
                    this.isEnabledPrev = false
                    this.isEnabledNext = false
                    this.isSubscribed = error.response.data.subscribed_until ? true : false
                    this.subscribedUntil = error.response.data.subscribed_until
                })
                .finally(() => this.isLoad = true)
            }
        },
        created () {
            if (this.$storage.get('nbe')) {
                this.isLoggedIn = true
            }
            this.params[this.sessionHeaderName] = this.$storage.get('nbe', 'no-session')
            if ('do' === process.env.VUE_APP_SESSION_CHECK) {
                // check session
                if (! this.$storage.get('nbe')) {
                    this.$router.push('/login')
                }
            }
        },
        mounted () {
            this.changeEdition()
            this.$gtm.trackView('Epaper Main Page', '/');
        },
        watch: {
            // whenever question changes, this function will run
            isLoggedIn: function (newIsLoggedIn) {
                if (! newIsLoggedIn) {
                    this.$router.push('/login')
                }
            }
        }
    }
</script>
