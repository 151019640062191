<template>
    <div class="flex h-screen bg-grey-lighter">
        <div class="hidden lg:flex xl:flex lg:w-1/2 xl:w-1/2" style="background: url('./img/bg.jpg'); background-repeat:no-repeat; background-size: cover;">
        </div>
        <div class="w-full lg:w-1/2 xl:w-1/2 shadow-lg">
            <div class="mx-auto flex justify-center items-center">
                <div class="w-full xl:w-1/2 lg:w-1/2">
                    <div class="text-center py-8">
                        <img class="rounded-lg" src="https://www.nusabali.com/img/apple-icon-180x180.png?v=2" style="width:84px;">
                    </div>
                        
                    <h1 class="text-xl py-8 text-center">Lupa Password?</h1>

                    <div class="xl:border lg:border rounded px-4">
                        <div class="mt-2">
                            <p class="text-center">Link pengaturan ulang password akan dikirimkan ke email Anda</p>
                        </div>
                        <div class="mt-2">
                            <input class="w-full border rounded py-2 px-2" type="text" name="email" v-model="email" placeholder="Email">
                        </div>
                        <div v-if="formErr" class="my-2 w-full py-4 bg-red-lightest relative">
                            <span v-on:click="closeErrorMessage()" class="cursor-pointer text-red-dark absolute" style="right: 10px; top: 4px">x</span>
                            <ul class="">
                                <li class="text-red-dark" v-for="message in errorMessage" v-bind:key="message">{{message}}</li>
                            </ul>
                        </div>
                        <div class="my-2">
                            <button :disabled="disableButton" class="w-full text-white bg-blue rounded py-2 px-6" type="button" v-on:click="doSignUp()">Kirim Link</button>
                        </div>
                        <div class="my-2 text-center">
                            <p>Ingat password Anda? <router-link to="/login" class="underline text-blue">Login</router-link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="fixed pin-b p-2 text-center text-sm w-full">
            &copy; 2019 NusaBali.com
        </footer>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                formErr: false,
                email: '',
                errorMessage: [],
                baseAPIUrl: process.env.VUE_APP_API_BASE_URL,
                disableButton: false
            }
        },
        methods: {
            closeErrorMessage() {
                this.formErr = false
                this.errorMessage = []
            },
            validate() {
                this.formErr = false
                this.errorMessage = []
                if (! this.email) {
                    this.formErr = true
                    this.errorMessage.push('Email harus diisi')
                }
                this.disableButton = false
            },
            doSignUp() {
                this.validate()
                if (! this.formErr) {
                    this.disableButton = true
                    var querystring = require('querystring');
                    this.axios.post(this.baseAPIUrl + 'forgot-password-step1', querystring.stringify({
                        email: this.email
                    })).then((response) => {
                        if (response.data.code === 0) {
                            this.$router.push('/login')
                        } else {
                            this.formErr = true
                            this.errorMessage.push(response.data.message)
                        }
                    }).finally(function() {
                        this.disableButton = false
                    })
                }
            }
        }
    }
</script>