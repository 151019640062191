<template>
    <div class="flex h-screen bg-grey-lighter">
        <div class="hidden lg:flex xl:flex lg:w-1/2 xl:w-1/2" style="background: url('./img/bg.jpg'); background-repeat:no-repeat; background-size: cover;">
        </div>
        <div class="w-full lg:w-1/2 xl:w-1/2 shadow-lg">
            <div class="mx-auto flex justify-center items-center">
                <div class="w-full xl:w-1/2 lg:w-1/2">
                    <div class="text-center py-8">
                        <img class="rounded-lg" src="https://www.nusabali.com/img/apple-icon-180x180.png?v=2" style="width:84px;">
                    </div>

                    <h1 class="text-xl py-8 text-center">Atur Ulang Password</h1>

                    <div class="xl:border lg:border rounded px-4">
                        <div class="mt-2">
                            <p class="text-center">Masukkan password baru Anda</p>
                        </div>
                        <div class="mt-2 relative">
                            <input class="w-full border rounded py-2 px-2" :type="passwordType" name="password" placeholder="Password" v-model="password">
                            <a :class="showPasswordColorClass" v-on:click="showPassword()"><span class="cursor-pointer icon-eye absolute" style="right:20px; top:10px;"></span></a>
                        </div>
                        <div v-if="formErr" class="my-2 w-full py-4 bg-red-lightest relative">
                            <span v-on:click="closeErrorMessage()" class="cursor-pointer text-red-dark absolute" style="right: 10px; top: 4px">x</span>
                            <ul class="">
                                <li class="text-red-dark" v-for="message in errorMessage" v-bind:key="message">{{message}}</li>
                            </ul>
                        </div>
                        <div class="my-2">
                            <button v-if="tokenOK" :disabled="disableButton" class="w-full text-white bg-blue rounded py-2 px-6" type="button" v-on:click="doResetPassword()">Reset Password</button>
                            <button v-if="! tokenOK" :disabled="disableButton" class="w-full text-white bg-grey-dark rounded py-2 px-6" type="button">Reset Password</button>
                        </div>
                        <div class="my-2 text-center">
                            <p>Kembali ke halaman <router-link to="/login" class="underline text-blue">Login</router-link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="fixed pin-b p-2 text-center text-sm w-full">
            &copy; 2019 NusaBali.com
        </footer>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                passwordType: 'password',
                showPasswordColorClass: 'text-blue',
                formErr: false,
                password: '',
                errorMessage: [],
                baseAPIUrl: process.env.VUE_APP_API_BASE_URL,
                disableButton: false,
                token: '',
                email: '',
                tokenOK: false
            }
        },
        mounted() {
            this.disableButton = true
            // check for token validity
            this.token = this.$route.query.token
            this.email = this.$route.query.email

            var querystring = require('querystring');
            this.axios.post(this.baseAPIUrl + 'check-forgot-token', querystring.stringify({
                email: this.email,
                token: this.token
            })).then((response) => {
                if (response.data.code !== 0) {
                    this.formErr = true
                    this.errorMessage.push(response.data.message)
                } else {
                    this.tokenOK = true
                }
            }).catch(() => {

            }).finally(() => {
                this.disableButton = false
            })
        },
        methods: {
            showPassword() {
                if (this.passwordType === 'password') {
                    this.passwordType = 'text';
                    this.showPasswordColorClass = 'text-gray';
                } else {
                    this.passwordType = 'password';
                    this.showPasswordColorClass = 'text-blue';
                }
            },
            closeErrorMessage() {
                this.formErr = false
                this.errorMessage = []
            },
            validate() {
                this.formErr = false
                this.errorMessage = []
                if (! this.password) {
                    this.formErr = true
                    this.errorMessage.push('Password harus diisi')
                }
                if (! this.token) {
                    this.formErr = true
                    this.errorMessage.push('Harap klik kembali link pada Email Atur Ulang Password')
                }
                if (! this.email) {
                    this.formErr = true
                    this.errorMessage.push('Harap klik kembali link pada Email Atur Ulang Password')
                }
                this.disableButton = false
            },
            doResetPassword() {
                this.validate()
                if (! this.formErr) {
                    this.disableButton = true
                    var querystring = require('querystring');
                    this.axios.post(this.baseAPIUrl + 'forgot-password-step2', querystring.stringify({
                        password: this.password,
                        email: this.email,
                        token: this.token
                    })).then(() => {
                        this.$router.push('/login')
                    }).finally(function() {
                        this.disableButton = false
                    })
                }
            }
        }
    }
</script>