<template>
    <div class="">
        <div class="px-4 py-4">
            <div class="flex justify-center">
                <div v-show="isProcessing" class="h-full"><spinner /></div>
                <div class="w-full xl:w-5/6 lg:w-5/6">
                    <div class="text-xl pt-8 pb-4 text-center w-full border-t border-r border-l rounded-t bg-white">
                        <h4>Berlangganan</h4>
                        <h1 class="text-3xl" style="transform: rotate(-1.2deg);">NusaBali E-Paper</h1>
                        <h3>Sekarang!</h3>
                    </div>
                    <ul class="list-reset bg-white px-4 py-2 border-t border-l border-r">
                        <li class="leading-loose"><span class="icon-check text-green"></span>Akses penuh ke <span class="font-bold">semua edisi</span></li>
                        <!-- <li class="leading-loose"><span class="icon-check text-green"></span>Akses selama <span class="font-bold">30 hari</span></li> -->
                        <li class="leading-loose"><span class="icon-check text-green"></span>Update <span class="font-bold">setiap pagi</span></li>
                        <li class="leading-loose"><span class="icon-check text-green"></span>Baca <span class="font-bold">di mana pun</span> dan <span class="font-bold">kapan pun</span></li>
                        <li class="leading-loose"><span class="icon-coffee text-green"></span>Ditemani <span class="font-bold">kopi</span> biar lebih mantab!</li>
                    </ul>
                    <div class="py-3 w-full border-b border-r border-l rounded-b text-blue-darker bg-blue-lightest">
                        <p class="text-center text-sm">Menerima pembayaran menggunakan GOPAY, QRIS, dan Bank Transfer</p>
                    </div>
                </div>
            </div>
            <div class="mt-8">
                <div class="lg:flex items-center justify-between">
                    <div class="mt-4 lg:mt-0">
                        <div class="py-3 text-center shadow-lg border-l border-r border-b bg-white font-bold text-2xl">1 bulan</div>
                        <div class="bg-green-light text-white py-4 text-center shadow" style="width:103%;margin-left:-1.5%;">
                            <!-- <h3 class="text-green-dark text-lg">Harga Simpel</h3> -->
                            <div class="flex justify-center px-8">
                                <span class="self-center text-xl">Rp </span>
                                <span class="text-4xl font-bold">50.000</span>
                                <!-- <small class="self-center">/ bulan</small> -->
                            </div>
                        </div>
                        <div class="py-3 text-center border-l border-r border-b bg-white  shadow-lg">
                            <button v-on:click="pay(1)" class="px-12 py-3 bg-blue-dark text-white rounded font-bold hover:text-blue-dark hover:bg-blue-lighter" :disabled="isProcessing">Pilih</button>
                        </div>
                    </div>
                    <div class="mt-4 lg:mt-0">
                        <div class="py-3 text-center shadow-lg border-l border-r border-b bg-white font-bold text-2xl">6 bulan</div>
                        <div class="bg-green-light text-white py-4 text-center shadow" style="width:103%;margin-left:-1.5%;">
                            <!-- <h3 class="text-green-dark text-lg">Harga Simpel</h3> -->
                            <div class="flex justify-center px-8">
                                <span class="self-center text-xl">Rp </span>
                                <span class="text-4xl font-bold">250.000</span>
                                <!-- <small class="self-center">/ bulan</small> -->
                            </div>
                        </div>
                        <div class="py-3 text-center border-l border-r border-b bg-white  shadow-lg">
                            <button v-on:click="pay(6)" class="px-12 py-3 bg-blue-dark text-white rounded font-bold hover:text-blue-dark hover:bg-blue-lighter" :disabled="isProcessing">Pilih</button>
                        </div>
                    </div>
                    <div class="mt-4 lg:mt-0">
                        <div class="py-3 text-center shadow-lg border-l border-r border-b bg-white font-bold text-2xl">12 bulan</div>
                        <div class="bg-green-light text-white py-4 text-center shadow" style="width:103%;margin-left:-1.5%;">
                            <!-- <h3 class="text-green-dark text-lg">Harga Simpel</h3> -->
                            <div class="flex justify-center px-8">
                                <span class="self-center text-xl">Rp </span>
                                <span class="text-4xl font-bold">500.000</span>
                                <!-- <small class="self-center">/ bulan</small> -->
                            </div>
                        </div>
                        <div class="py-3 text-center border-l border-r border-b bg-white  shadow-lg">
                            <button v-on:click="pay(12)" class="px-12 py-3 bg-blue-dark text-white rounded font-bold hover:text-blue-dark hover:bg-blue-lighter" :disabled="isProcessing">Pilih</button>
                        </div>
                    </div>
                </div>
                <div v-if="displayAsPopup=='no'" class="mt-8 text-center">
                    <router-link to="/" class="px-8 py-2 no-underline border border-blue-dark text-blue-dark rounded font-bold hover:text-white hover:bg-blue-dark">Kembali</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Spinner from './Spinner'

    export default {
        components: {
            Spinner
        },
        props: {
            'asPopup': {
                type: String,
                default: 'yes'
            }
        },
        data() {
            return {
                isLoggedIn: false,
                isProcessing: false,
                baseAPIUrl: process.env.VUE_APP_API_BASE_URL,
                displayAsPopup: this.asPopup
            }
        },
        head: {
            script: [
                { type: 'text/javascript', src: process.env.VUE_APP_MIDTRANS_SNAP_URL, 'data-client-key': process.env.VUE_APP_MIDTRANS_CLIENT_KEY, body: true } // Insert in body
            ]
        },
        created() {
            if (this.$storage.get('nbe')) {
                this.isLoggedIn = true
            }
            if ('do' === process.env.VUE_APP_SESSION_CHECK) {
                // check session
                if (! this.$storage.get('nbe')) {
                    this.$router.push('/login')
                }
            }
        },
        mounted() {
            setTimeout(() => {
                //do nothing, just wait for snap.js loads, well atleast for 1 sec
            }, 1000)
        },
        methods: {
            pay: function(qty) {
                let item
                switch(qty) {
                    case 1:
                        item = 1
                        break
                    case 6:
                        item = 6
                        break
                    case 12:
                        item = 12
                        break
                    default:
                        return
                }
                this.isProcessing = true
                // request to API to get transactionID
                this.axios.post(this.baseAPIUrl + "checkout/" + item, {}, {
                    headers: {
                        'X-NBE': this.$storage.get('nbe')
                    }
                }).then((response) => {
                    // eslint-disable-next-line no-use-before-define
                    snap.pay(response.data.token, {
                        onError: () => {
                            this.isProcessing = false
                        },
                        onClose: () => {
                            console.log('Closing')
                            this.isProcessing = false
                        }
                    })
                })
            }
        }
    }
</script>

