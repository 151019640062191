<template>
    <div class="flex h-screen bg-grey-lighter">
        <div class="hidden lg:flex xl:flex lg:w-1/2 xl:w-1/2" style="background: url('./img/bg.jpg'); background-repeat:no-repeat; background-size: cover;">
        </div>
        <langganan-pop-up class="w-full p-4 lg:p-0 xl:p-0 lg:w-1/2 xl:w-1/2 shadow-lg" as-popup="no"></langganan-pop-up>
    </div>
</template>

<script>
import LanggananPopUp from './LanggananPopUp'

export default {
    components: {
        'langganan-pop-up': LanggananPopUp
    }
}
</script>