<template>
	<div class="flex h-screen bg-grey-lighter">
		<div class="hidden lg:flex xl:flex lg:w-1/2 xl:w-1/2" style="background: url('./img/bg.jpg'); background-repeat:no-repeat; background-size: cover;">
		</div>
		<div class="w-full lg:w-1/2 xl:w-1/2 shadow-lg">
			<div class="mx-auto flex justify-center items-center">
				<div class="w-full xl:w-1/2 lg:w-1/2">
					<div class="text-center py-8">
						<img class="rounded-lg" src="https://www.nusabali.com/img/apple-icon-180x180.png?v=2" style="width:84px;">
					</div>
						
					<h1 class="text-xl py-8 text-center">Daftar NusaBali E-Paper</h1>

					<div class="xl:border lg:border rounded px-4">
						<div class="mt-2">
							<input class="w-full border rounded py-2 px-2" type="text" name="name" v-model="name" placeholder="Nama Lengkap">
						</div>
						<div class="mt-2">
							<input class="w-full border rounded py-2 px-2" type="text" name="email" v-model="email" placeholder="Email">
						</div>
						<div class="mt-2">
							<input class="w-full border rounded py-2 px-2" type="text" name="emailconfirm" v-model="emailconfirm" placeholder="Ulangi Email">
						</div>
						<div class="mt-2 relative">
							<input class="w-full border rounded py-2 px-2" :type="passwordType" name="password" placeholder="Password" v-model="password">
							<a :class="showPasswordColorClass" v-on:click="showPassword()"><span class="cursor-pointer icon-eye absolute" style="right:20px; top:10px;"></span></a>
						</div>
						<div v-if="formErr" class="my-2 w-full py-4 bg-red-lightest relative">
							<span v-on:click="closeErrorMessage()" class="cursor-pointer text-red-dark absolute" style="right: 10px; top: 4px">x</span>
							<ul class="">
								<li class="text-red-dark" v-for="message in errorMessage" :key="message">{{message}}</li>
							</ul>
						</div>
						<div class="my-2">
							<button :disabled="disableButton" class="w-full text-white bg-blue rounded py-2 px-6" type="button" v-on:click="doSignUp()">Daftar</button>
						</div>
						<div class="my-2 text-center">
							<p>Sudah daftar sebelumnya? <router-link to="/login" class="underline text-blue">Login</router-link></p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<footer class="fixed pin-b p-2 text-center text-sm w-full">
			&copy; 2019 NusaBali.com
		</footer>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				passwordType: 'password',
				showPasswordColorClass: 'text-blue',
				formErr: false,
				password: '',
				name: '',
				email: '',
				emailconfirm: '',
				errorMessage: [],
				baseAPIUrl: process.env.VUE_APP_API_BASE_URL,
				disableButton: false
			}
		},
		methods: {
			showPassword() {
				if (this.passwordType === 'password') {
					this.passwordType = 'text';
					this.showPasswordColorClass = 'text-gray';
				} else {
					this.passwordType = 'password';
					this.showPasswordColorClass = 'text-blue';
				}
			},
			closeErrorMessage() {
				this.formErr = false
				this.errorMessage = []
			},
			validate() {
				this.formErr = false
				this.errorMessage = []
				if (! this.name) {
					this.formErr = true
					this.errorMessage.push('Nama Lengkap harus diisi')
				}
				if (! this.email) {
					this.formErr = true
					this.errorMessage.push('Email harus diisi')
				}
				if (! this.emailconfirm) {
					this.formErr = true
					this.errorMessage.push('Konfirmasi email harus diisi')
				}
				if (! this.password) {
					this.formErr = true
					this.errorMessage.push('Password harus diisi')
				}
				if (this.email !== this.emailconfirm) {
					this.formErr = true
					this.errorMessage.push('Email dan Email Konfirmasi tidak sama')
				}
				this.disableButton = false
			},
			doSignUp() {
				this.validate()
				if (! this.formErr) {
					this.disableButton = true
					var querystring = require('querystring');
					this.axios.post(this.baseAPIUrl + 'signup', querystring.stringify({
						name: this.name,
						email: this.email,
						password: this.password
					})).then((response) => {
						if (response.data.code === 0) {
							this.$router.push('/login')
						} else {
							this.formErr = true
							this.errorMessage.push(response.data.message)
						}
					}).catch(() => {
						this.disableButton = false
					}).finally(() => {
						this.disableButton = false
					})
				}
			}
		}
	}
</script>