<template>
    <div class="flex h-screen bg-grey-lighter">
        <div class="hidden lg:flex xl:flex lg:w-1/2 xl:w-1/2" style="background: url('./img/bg.jpg'); background-repeat:no-repeat; background-size: cover;">
        </div>
        <div class="w-full lg:w-1/2 xl:w-1/2 shadow-lg">
            <div class="mx-auto flex justify-center items-center">
                <div class="w-full xl:w-1/2 lg:w-1/2">
                    <div class="text-center py-8">
                        <img class="rounded-lg" src="https://www.nusabali.com/img/apple-icon-180x180.png?v=2" style="width:84px;">
                    </div>
                        
                    <h1 class="text-xl py-8 text-center">Verifikasi Email</h1>

                    <div class="xl:border lg:border rounded px-4 mb-2 pb-4">
                        <div class="text-center">
                            <h2 v-if="result == 'ok'" class="text-lg">Verifikasi Sukses</h2>
                            <h3 v-if="!email || !token" class="text-base">Data verifikasi tidak lengkap, mohon klik tombol <b>Verifikasi</b> dalam Email Verifikasi</h3>
                            <p v-if="errorMessage" class="my-2 w-full py-4 bg-red-lightest text-base text-red-dark">{{errorMessage}}</p>
                        </div>
                        <div class="my-2 text-center mt-4">
                            <p><router-link to="/login" class="px-4 py-1 xl:py-2 lg:py-2 mr-2 my-2 no-underline rounded border border-blue-dark text-base hover:text-white text-blue-dark hover:bg-blue-dark">Login</router-link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="fixed pin-b p-2 text-center text-sm w-full">
            &copy; 2019 NusaBali.com
        </footer>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                token: '',
                email: '',
                baseAPIUrl: process.env.VUE_APP_API_BASE_URL,
                result: 'pending',
                errorMessage: ''
            }
        },
        mounted() {
            this.token = this.$route.query.token
            this.email = this.$route.query.email

            if (this.token && this.email) {
                var querystring = require('querystring');
                this.axios.post(this.baseAPIUrl + 'verify', querystring.stringify({
                    email: this.email,
                    token: this.token
                })).then((response) => {
                    if (response.data.code === 0) {
                        this.result = 'ok'
                    } else {
                        this.result = 'notok'
                        this.errorMessage = response.data.message
                    }
                }).catch(() => {
                    this.result = 'notok'
                })
            }
        }
    }
</script>