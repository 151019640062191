import Vue from 'vue'
import axios from 'axios'
import VueRouter from 'vue-router'
import VueAxios from 'vue-axios'
// import VueAuthenticate from 'vue-authenticate'
import App from './App.vue'
import {Vue2Storage} from 'vue2-storage'
import '@/assets/css/main.css'
import './assets/css/font-css/css/fontello.css'

import Login from './components/Login.vue'
import Main from './components/Main.vue'
import Subscription from './components/Subscription.vue'
import Purchased from './components/Purchased.vue'
import Register from './components/Register.vue'
import ForgotPassword from './components/ForgotPassword.vue'
import ForgotPassword2 from './components/ForgotPassword2.vue'
import Verify from './components/Verify.vue'
import GSignInButton from 'vue-google-signin-button'
import VueHead from 'vue-head'
import VTooltip from 'v-tooltip'
import VueMobileDetection from "vue-mobile-detection"
import VueGtm from 'vue-gtm'

// axios.defaults.baseURL = 'http://localhost:8080';

Vue.config.productionTip = false
Vue.use(VueHead)
Vue.use(VueRouter)
Vue.use(Vue2Storage, {
  driver: 'local',
  ttl: 30 * 60 * 60 * 24 * 1000 // 24 часа
})
Vue.use(VueAxios, axios)
Vue.use(GSignInButton)
Vue.use(VTooltip)
Vue.use(VueMobileDetection)

const routes = [
    { path: '/', component: Main },
    { path: '/login', name: 'login', component: Login },
    { path: '/daftar', name: 'daftar', component: Register },
    { path: '/verify', name: 'verify', component: Verify },
    { path: '/langganan', component: Subscription },
    { path: '/pembelian', component: Purchased },
    { path: '/forgot-password', component: ForgotPassword },
    { path: '/reset-password', component: ForgotPassword2 }
]

const router = new VueRouter({
    mode: 'history',
    routes: routes
})

Vue.use(VueGtm, {
  id: 'GTM-PS94Z2J', // Your GTM ID
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
});

Vue.mixin({
    created() {
        // disable rightclick and inspects
        document.addEventListener("contextmenu", function(e){
          e.preventDefault();
        }, false);
        document.addEventListener("keydown", function(e) {
        //document.onkeydown = function(e) {
            // "I" key
            if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
                disabledEvent(e);
            }

            // Mac cmd + option + "I" key
            if (navigator.platform.match("Mac") && e.metaKey && e.altKey && e.keyCode == 73) {
                disabledEvent(e);
            }

            // "J" key
            if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
                disabledEvent(e);
            }

            // "S" key + macOS
            if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                disabledEvent(e);
            }
            // "U" key
            if (e.ctrlKey && e.keyCode == 85) {
                disabledEvent(e);
            }
            // "F12" key
            if (event.keyCode == 123) {
                disabledEvent(e);
            }
        }, false);
        function disabledEvent(e){
          if (e.stopPropagation){
            e.stopPropagation();
          } else if (window.event){
            window.event.cancelBubble = true;
          }
          e.preventDefault();
          return false;
        }
        function absorbEvent_(event) {
            var e = event || window.event;
            e.preventDefault && e.preventDefault();
            e.stopPropagation && e.stopPropagation();
            e.cancelBubble = true;
            e.returnValue = false;
            return false;
        }
        function preventLongPressMenu(node) {
            node.ontouchstart = absorbEvent_;
            node.ontouchmove = absorbEvent_;
            node.ontouchend = absorbEvent_;
            node.ontouchcancel = absorbEvent_;
        }
        preventLongPressMenu(document.getElementsByTagName("img"))
    }
})

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
