<template>
    <div class="flex h-screen bg-grey-lighter">
        <div class="hidden lg:flex xl:flex lg:w-1/2 xl:w-1/2" style="background: url('./img/bg.jpg'); background-repeat:no-repeat; background-size: cover;">
        </div>
        <div class="w-full lg:w-1/2 xl:w-1/2 shadow-lg">
            <div class="mx-auto flex justify-center items-center">
                <div class="w-full xl:w-3/4 lg:w-3/4">
                    <div class="text-center py-8">
                        <h1>NusaBali Epaper</h1>
                    </div>
                    <h3 class="text-xl py-2 text-center">Detil Pembelian</h3>
                    <div class="w-3/4 mx-auto">
                        <div class="flex justify-between mt-2">
                            <div class="font-bold">ID Transaksi</div>
                            <div>{{transactionData.id}}</div>
                        </div>
                        <div class="flex justify-between mt-2">
                            <div class="font-bold">Langganan Epaper 1 Bulan</div>
                            <div>{{transactionData.total_amount}}</div>
                        </div>
                        <div class="flex justify-between mt-2">
                            <div class="font-bold">Status Transaksi</div>
                            <div>{{transactionData.status}}</div>
                        </div>
                        <div class="flex justify-between mt-2">
                            <div class="font-bold">Tanggal Transaksi</div>
                            <div>{{transactionData.updated_at}}</div>
                        </div>
                        <div class="flex justify-between mt-2">
                            <div class="font-bold">Berakhir pada</div>
                            <div>{{transactionData.end_date}}</div>
                        </div>

                        <div v-if="isPending" class="text-center mt-4">
                            <img id="preview" :src="transactionData.qrSrc" class="w-1/2">
                            <p class="mt-4">
                                Scan QR Code di atas dengan aplikasi GOJEK untuk melanjutkan pembayaran
                            </p>
                            <div v-if="isMobile" class="mt-2">
                                <p class="mb-4">Atau klik tombol dibawah ini</p>
                                <a class="px-4 py-1 xl:py-2 lg:py-2 mr-2 my-2 no-underline rounded border border-blue-dark text-base text-white hover:text-blue-dark bg-blue-dark hover:bg-white" :href="transactionData.deeplink">Bayar Sekarang</a>
                            </div>
                        </div>
                        <div class="mt-12 text-center">
                            <router-link to="/" class="px-4 py-1 xl:py-2 lg:py-2 mr-2 my-2 no-underline rounded border border-blue-dark text-base hover:text-white text-blue-dark hover:bg-blue-dark">Kembali ke halaman utama</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="fixed pin-b p-2 text-center text-sm w-full">
            &copy; 2019 NusaBali.com
        </footer>
    </div>
</template>

<script>
    import QrCode from 'qrcode-reader';

    export default {
        data() {
            return {
                isLoggedIn: false,
                isPending: false,
                baseAPIUrl: process.env.VUE_APP_API_BASE_URL,
                transactionData: {
                    id: '',
                    amount: '',
                    updated_at: '',
                    end_date: '',
                    status: '',
                    total_amount: '',
                    qrSrc: '',
                    deeplink: ''
                },
                isMobile: false
            }
        },
        localStorage: {
            nbe: {
                type: String,
                default: ''
            }
        },
        created() {
            if (this.$storage.get('nbe')) {
                this.isLoggedIn = true
            }
            if ('do' === process.env.VUE_APP_SESSION_CHECK) {
                // check session
                if (! this.$storage.get('nbe')) {
                    this.$router.push('/login')
                }
            }
        },
        mounted() {
            this.isMobile = this.$isMobile() ? true : false
            // check API for transaction detail, redirect if not valid (logged in, for that user, is subscribed)
            this.axios.get(this.baseAPIUrl + 'latest-transaction/?ts=' + new Date().getTime(), {
                headers: {
                    'X-NBE': this.$storage.get('nbe')
                }
            }).then((response) => {
                this.transactionData.id = response.data.data.transaction_id
                this.transactionData.total_amount = 'Rp' + response.data.data.total_amount
                this.transactionData.updated_at = response.data.data.updated_at
                this.transactionData.end_date = response.data.data.end_date
                this.transactionData.status = response.data.data.status
                if (response.data.data.status === 'pending') {
                    this.transactionData.qrSrc = response.data.data.qr_code
                    this.isPending = true

                    if (this.$isMobile()) {
                        // read qr code if mobile
                        this.axios.get(this.baseAPIUrl + 'qr/' + this.transactionData.id, {
                            responseType: 'arraybuffer',
                            headers: {
                                'X-NBE': this.$storage.get('nbe')
                            }
                        }).then(async (response) => {
                            let dataURI = new Buffer(response.data, 'binary').toString('base64')
                            // alert(c.toDataURL());
                            let qr = new QrCode()
                            qr.callback = (err, result) => {
                                if(result){
                                    let qrData = JSON.parse(result.result)
                                    //gojek://gopay/merchanttransfer?tref=i09k0FF8GF&amount=1200&activity=GP:RR&callback_url=
                                    this.transactionData.deeplink = 'gojek://gopay/merchanttransfer?tref='+ qrData.data.tref +'&amount='+qrData.data.amount+'&activity='+qrData.activity+'&callback_url=' + process.env.VUE_APP_BASE_URL + '#/pembelian'
                                }
                                else{
                                    console.error(err);
                                }
                            }
                            qr.decode('data:image/png;base64,' + dataURI)
                        })
                    }
                }
            }).catch(() => {
                this.$router.push('/')
            })
        }
    }
</script>