<template>
    <div class="flex h-screen bg-grey-lighter">
        <div v-show="! loadGAPI || isProcessing" class="h-full"><spinner /></div>
        <div class="hidden lg:flex xl:flex lg:w-1/2 xl:w-1/2" style="background: url('./img/bg.jpg'); background-repeat:no-repeat; background-size: cover;">
        </div>
        <div class="w-full lg:w-1/2 xl:w-1/2 shadow-lg">
            <div class="mx-auto flex justify-center items-center">
                <div class="w-full xl:w-1/2 lg:w-1/2">
                    <div class="text-center py-8">
                        <img class="rounded-lg" src="https://www.nusabali.com/img/apple-icon-180x180.png?v=2" style="width:84px;">
                    </div>

                    <h1 class="text-xl py-8 text-center">Masuk NusaBali E-Paper</h1>

                    <div class="xl:border lg:border rounded px-4">
                        <div class="my-2">
                            <div class="cursor-pointer">
                                <v-facebook-login
                                  v-model="facebook.model"
                                  :app-id="facebook.appId"
                                  :params="fbSignInParams"
                                  :button-style="buttonStyle"
                                  :transition="[ 'padding-right 0.15s ease-in-out' ]"
                                  @sdk-init="handleSdkInit"
                                  @connect="handleConnect"
                                  @logout="handleLogout"
                                  @click="handleClick"
                                  class="w-full text-white bg-blue-darkest rounded py-3 px-6 mb-2 cursor-pointer"
                                >
                                    <template slot="after" slot-scope="{}">
                                        <span class="docs-avatar"
                                        :style="avatarStyle"
                                    ></span>
                                    </template>
                                </v-facebook-login>
                            </div>
                            <!-- <div class="mt-4" v-show="! loadGAPI">
                                <button class="w-full text-white bg-blue-darkest rounded py-3 px-6 mb-2 text-center cursor-not-allowed opacity-25"><span class="icon-google"></span> Sign in with Google</button>
                            </div>
                            <div class="mt-4" v-if="loadGAPI">
                                <template>
                                    <g-signin-button
                                        :params="googleSignInParams"
                                        @success="onSignInSuccess"
                                        @error="onSignInError" class="w-full text-white bg-blue-darkest rounded py-3 px-6 mb-2 text-center  cursor-pointer hover:opacity-75">
                                        <span class="icon-google"></span> Sign in with Google
                                    </g-signin-button>
                                </template>
                            </div> -->
                            <div class="mt-4 text-center">
                                <p>Atau Login menggunakan Email</p>
                            </div>
                            <div class="mt-4">
                                <div class="mt-2">
                                    <input class="w-full border border-gray p-2 rounded mb-1" type="text" name="email" placeholder="Email" v-model="email">
                                </div>
                                <div class="mt-2 relative">
                                    <input class="w-full border rounded py-2 px-2" :type="passwordType" name="password" placeholder="Password" v-model="password">
                                    <a :class="showPasswordColorClass" v-on:click="showPassword()"><span class="cursor-pointer icon-eye absolute" style="right:20px; top:10px;"></span></a>
                                </div>
                                <div v-if="formErr" class="my-2 w-full py-4 bg-red-lightest relative">
                                    <span v-on:click="closeErrorMessage()" class="cursor-pointer text-red-dark absolute" style="right: 10px; top: 4px">x</span>
                                    <ul class="">
                                        <li class="text-red-dark" v-for="message in errorMessage" :key="message">{{message}}</li>
                                    </ul>
                                </div>
                                <button :disabled="disableButton" v-on:click="doEmailLogin()" class="w-full text-white bg-blue rounded py-3 px-6 mb-2 text-center  cursor-pointer hover:opacity-75 my-2" type="button">Login</button>
                                <div class="text-center">
                                    <p>
                                        <router-link to="/forgot-password" class="underline text-blue">Lupa Password?</router-link> | 
                                        <router-link to="/daftar" class="underline text-blue">Daftar menggunakan Email?</router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="fixed pin-b p-2 text-center text-sm w-full">
            &copy; 2019 NusaBali.com
        </footer>
    </div>
</template>

<script>
    import VFacebookLogin from 'vue-facebook-login-component'
    import typy from 'typy'
    import Spinner from './Spinner'

    export default {
        components: {
            VFacebookLogin,
            'spinner': Spinner
        },
        head: {
            script: [
                { type: 'text/javascript', src: 'https://apis.google.com/js/api:client.js' }
            ],
        },
        data: () => ({
            email: '',
            password: '',
            passwordType: 'password',
            formErr: false,
            errorMessage: [],
            showPasswordColorClass: 'text-blue',
            disableButton: false,
            facebook: {
                FB: {},
                model: {},
                appId: '594879160702424'
            },
            fbSignInParams: {
                scope: 'email',
                return_scopes: true
            },
            flags: {
                nopicture: false
            },
            user: {
                picture: { data: { url: '' } }
            },
            /**
               * The Auth2 parameters, as seen on
               * https://developers.google.com/identity/sign-in/web/reference#gapiauth2initparams.
               * As the very least, a valid client_id must present.
               * @type {Object}
               */
            googleSignInParams: {
                client_id: '22694591689-i7vgtrvalmott9j4p8vsgifl40ngc8u3.apps.googleusercontent.com'
            },
            loadGAPI: false,
            isProcessing: false,
            baseAPIUrl: process.env.VUE_APP_API_BASE_URL
        }),
        localStorage: {
            nbe: {
                type: String,
                default: ''
            },
            source: {
                type: String,
                default: ''
            }
        },
        created() {
            // wait to fetch GAPI
            setTimeout(() => {
                this.loadGAPI = true
            }, 1500)
        },
        computed: {
            computed() {
                const name = this.user.name
                const picture = typy(this.user, 'picture.data.url').safeString
                return { name, picture }
            },
            connected() {
                return this.facebook.model.connected
            },
            disconnected() {
                return !this.connected
            },
            buttonStyle() {
                const rules = {
                    position: 'relative'
                }
                if (this.connected && this.computed.picture) {
                    rules.paddingRight = '3.375rem'
                }
                return rules
            },
            avatarStyle() {
                return {
                    backgroundImage: `url(${this.computed.picture})`
                }
            }
        },
        methods: {
            showPassword() {
                if (this.passwordType === 'password') {
                    this.passwordType = 'text';
                    this.showPasswordColorClass = 'text-gray';
                } else {
                    this.passwordType = 'password';
                    this.showPasswordColorClass = 'text-blue';
                }
            },
            // facebook
            getUserData() {
                const { api } = this.facebook.FB
                api('/me', { fields: 'id, name, email' }, user => {
                    this.user = Object.assign({}, this.user, user)
                    api(`${this.user.id}/picture?width=9999&redirect=false`, picture => {
                        if (picture) {
                            if (picture.data.url === this.user.picture.data.url) {
                                // Do not update
                            } else {
                                this.$set(this.user, 'picture', picture)
                            }
                        } else {
                            this.flags.nopicture = true
                        }
                        // console.log(user)
                        // do login to API
                        this.doLogin(user.email, 'facebook', user.id, user.name);
                    })
                })
            },
            handleSdkInit({ FB }) {
                this.facebook.FB = FB
            },
            handleConnect() {
                this.getUserData()
            },
            handleLogout() {
                // eslint-disable-next-line
                // console.info('[V-Facebook-Login info]: Logged `logout` event.')
            },
            handleClick() {
                // eslint-disable-next-line
                // console.info('[V-Facebook-Login info]: Logged `click` event.')
                this.isProcessing = true
            },
            // google
            onSignInSuccess (googleUser) {
                // `googleUser` is the GoogleUser object that represents the just-signed-in user.
                // See https://developers.google.com/identity/sign-in/web/reference#users
                const profile = googleUser.getBasicProfile() // etc etc
                // do login to API
                // console.log(profile)
                this.doLogin(profile.zu, 'google', profile.SU, profile.Ad);
            },
            onSignInError (error) {
                // `error` contains any error occurred.
                console.log('OH NOES', error)
            },
            closeErrorMessage() {
                this.formErr = false
                this.errorMessage = []
            },
            validate() {
                this.formErr = false
                this.errorMessage = []
                if (! this.email) {
                    this.formErr = true
                    this.errorMessage.push('Email harus diisi')
                }
                if (! this.password) {
                    this.formErr = true
                    this.errorMessage.push('Password harus diisi')
                }
                this.disableButton = false
            },
            doEmailLogin() {
                this.validate()
                if (! this.formErr) {
                    this.disableButton = true
                    var querystring = require('querystring');
                    this.axios.post(this.baseAPIUrl + 'login', querystring.stringify({
                        email: this.email,
                        password: this.password
                    })).then((response) => {
                        if (response.data.code === 0) {
                            this.$storage.set('nbe', response.headers['x-nbe'], { ttl: 60 * 60 * 24 * 30 * 1000 })
                            this.$storage.set('source', 'email', { ttl: 60 * 60 * 24 * 30 * 1000 })

                            this.$router.push('/')
                        } else {
                            this.formErr = true
                            this.errorMessage.push(response.data.message)
                            if (response.data.message === 'Email belum terverifikasi.') {
                                this.errorMessage.push('Harap periksa email verifikasi dari kami (cek juga dalam spam folder)')
                            }
                        }
                    }).finally(() => {
                        this.disableButton = false
                    })
                }
            },
            doLogin(email, source, id, user_name) {
                var querystring = require('querystring');
                this.axios.post(this.baseAPIUrl + 'auth/?ts=' + new Date().getTime(), querystring.stringify(
                    {
                        email: email,
                        source: source,
                        id: id,
                        user_name: user_name
                    }))
                .then((response) => {
                    // console.log(response)
                    // store users session and source of sign in to localstorage
                    this.$storage.set('nbe', response.headers['x-nbe'], { ttl: 60 * 60 * 24 * 30 * 1000 })
                    this.$storage.set('source', source, { ttl: 60 * 60 * 24 * 30 * 1000 })

                    // redirect to main page
                    this.$router.push('/')
                })
            }
        }
    }
</script>